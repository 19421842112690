import React from 'react'
import cs from 'classnames'

export const Paper: React.FC<{
  title?: string
  subtitle?: string
  bgColor?: string
  currentTabName?: string
}> = ({ bgColor, children }) => {
  return (
    <>
      {!bgColor && (
        <div
          className={cs(
            'fixed bg-white sm:bg-gray-100 top-0 left-0 w-full h-full z-0'
          )}
        ></div>
      )}

      <div className={cs('pb-12')}>{children}</div>
    </>
  )
}
