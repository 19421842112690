import {
  cardServiceFeePercent,
  achServiceFeePercent,
  firstTimeVerificationFeeUSD,
  firstTimeACHFeeUSD,
  firstTimeCardFeeUSD
} from 'lib/config'

import { AccountSetupFees, Fees, PaymentMethod, TokenCurrency } from 'lib/types'
import { getDefaultNetworkFeesUSD, getNetworkFeesUSD } from './networkFees'

export interface FirstPurchaseResult {
  isFirstPurchase: boolean
  isFirstACHPurchase: boolean
  isFirstCardPurchase: boolean
}

/**
 * Determine if the given network fee is in a spread radius from the expected
 * Helps with verifying fees before placing an order
 */
export function compareNetworkFees(
  networkFees: number,
  expectedNetworkFees: number,
  spread: number
) {
  const upperLimit = expectedNetworkFees + spread
  const lowerLimit = expectedNetworkFees - spread

  if (networkFees < lowerLimit || networkFees > upperLimit) {
    throw Error('network fees given is out of the acceptable limit')
  }
}

/**
 * Compares that two given Fees object have similar values
 * Helps with verifying fees before placing an order
 */
export function compareFees(fees: Fees, expectedFees: Fees) {
  if (
    fees.accountSetup.firstTimeVerification !==
    expectedFees.accountSetup.firstTimeVerification
  ) {
    throw Error('firstTimeVerification fee does not match')
  }

  if (
    fees.accountSetup.firstTimeACH !== expectedFees.accountSetup.firstTimeACH
  ) {
    throw Error('firstTimeACH fee does not match')
  }

  if (
    fees.accountSetup.firstTimeCard !== expectedFees.accountSetup.firstTimeCard
  ) {
    throw Error('firstTimeACH fee does not match')
  }

  if (fees.onRamp !== expectedFees.onRamp) {
    throw Error('onRamp fee does not match')
  }

  compareNetworkFees(fees.network, expectedFees.network, 0.01)
}

/**
 * Get fees using default settings (network fees = 1; isFirst*Purchase = false)
 * Meant to help with placeholders
 */
export function getDefaultFeesUSD(
  amount: number,
  paymentMethod: PaymentMethod,
  token: TokenCurrency
): Fees {
  const defaultFirstTimeStatus = {
    isFirstPurchase: false,
    isFirstACHPurchase: false,
    isFirstCardPurchase: false
  }
  const onRampFees = getOnrampFeesUSD(amount, paymentMethod)
  const accountSetupFees = getAccountSetupFeesUSD(
    paymentMethod,
    defaultFirstTimeStatus
  )

  return {
    network: getDefaultNetworkFeesUSD(token),
    onRamp: onRampFees,
    accountSetup: accountSetupFees
  }
}

export async function getFeesUSD(
  amount: number,
  paymentMethod: PaymentMethod,
  firstPurchaseResult: FirstPurchaseResult,
  networkFees: number
): Promise<Fees> {
  const onRampFees = getOnrampFeesUSD(amount, paymentMethod)
  const accountSetupFees = getAccountSetupFeesUSD(
    paymentMethod,
    firstPurchaseResult
  )

  return {
    network: networkFees,
    onRamp: onRampFees,
    accountSetup: accountSetupFees
  }
}

/**
 * Get fees associated with on ramping. Usually Supercharge's service fee
 */
export function getOnrampFeesUSD(
  amount: number,
  paymentMethod: PaymentMethod
): number {
  let onRampFeePercent

  if (paymentMethod === PaymentMethod.ACH) {
    onRampFeePercent = achServiceFeePercent
  } else {
    onRampFeePercent = cardServiceFeePercent
  }

  return (onRampFeePercent / 100) * amount
}

/**
 * Get fees associated with account set up. E.g KYC verification or ACH setup charges
 */
export function getAccountSetupFeesUSD(
  paymentMethod: PaymentMethod,
  firstPurchaseResult: FirstPurchaseResult
): AccountSetupFees {
  let firstTimeVerificationFee = firstTimeVerificationFeeUSD
  let firstTimeACHFee = firstTimeACHFeeUSD
  let firstTimeCardFee = firstTimeCardFeeUSD

  if (!firstPurchaseResult.isFirstPurchase) {
    firstTimeVerificationFee = 0.0
  }

  if (paymentMethod === PaymentMethod.ACH) {
    firstTimeCardFee = 0.0
    if (!firstPurchaseResult.isFirstACHPurchase) {
      firstTimeACHFee = 0.0
    }
  } else {
    firstTimeACHFee = 0.0
    if (!firstPurchaseResult.isFirstCardPurchase) {
      firstTimeCardFee = 0.0
    }
  }

  return {
    firstTimeVerification: firstTimeVerificationFee,
    firstTimeACH: firstTimeACHFee,
    firstTimeCard: firstTimeCardFee
  }
}

/**
 * Gets total fees in maximum decimal places.
 */
export function getTotalFeesUSD(fees: Fees): number {
  const totalFees =
    getTotalOnrampFeesUSD(fees) + getTotalAccountSetupFeesUSD(fees)
  return totalFees
}

/**
 * Gets total fees. This should always round to 2 decimal points
 */
export function getTotalFeesRoundedUSD(fees: Fees): number {
  const totalFees =
    getTotalOnrampFeesUSD(fees) + getTotalAccountSetupFeesUSD(fees)
  return Number(totalFees.toFixed(2))
}

/**
 * Gets total fees for account setup in maximum decimal places
 */
export function getTotalAccountSetupFeesUSD(fees: Fees): number {
  const accountSetupFees = fees.accountSetup
  const totalAccountSetupFees =
    accountSetupFees.firstTimeVerification +
    accountSetupFees.firstTimeACH +
    accountSetupFees.firstTimeCard
  return totalAccountSetupFees
}

/**
 * Gets total fees for account setup. This should always round to 2 decimal points
 */
export function getTotalAccountSetupFeesRoundedUSD(fees: Fees): number {
  const accountSetupfees = getTotalAccountSetupFeesUSD(fees)
  return Number(accountSetupfees.toFixed(2))
}

/**
 * Gets total fees for onramp (service + network) in maximum decimal places
 */
export function getTotalOnrampFeesUSD(fees: Fees): number {
  const totalOnrampFees = fees.network + fees.onRamp
  return totalOnrampFees
}

/**
 * Gets total fees for onramp (service + network). This should always round to 2 decimal points
 */
export function getTotalOnrampFeesRoundedUSD(fees: Fees): number {
  const onRampfees = getTotalOnrampFeesUSD(fees)
  return Number(onRampfees.toFixed(2))
}

/**
 * Gets final token amount after fees are applied. We always charge the total fees, rounded to 2 decimal places.
 */
export function getFinalDestinationTokenAmount(
  fiatAmount: number,
  exchangeRate: number,
  fees: Fees
): number {
  const totalFeesCharged = getTotalFeesRoundedUSD(fees)
  const finalAmount = (fiatAmount - totalFeesCharged) * exchangeRate
  if (finalAmount < 0) {
    return 0
  }
  return Number(finalAmount.toFixed(7))
}
