import {
  isDev,
  primetrustFeesAccountId,
  primetrustSettlementAccountId
} from 'lib/config'

import { getClient } from 'lib/partner/primetrust'

import { AccountStatus, Fees, KYCAccountData } from 'lib/types'
import { getTotalFeesRoundedUSD } from '../fees'

async function isTransferAuthorized(
  fromAccountNumber: string,
  toAccountId: string
): Promise<boolean> {
  const primetrustClient = await getClient()
  const accountTransferAuthorization =
    await primetrustClient.GetAccountTransferAuthorization({
      'from-account-number': fromAccountNumber
    })

  const accountTransferAuthorizations = accountTransferAuthorization.data
  if ((accountTransferAuthorizations as Array<any>).length === 0) {
    return false
  }

  for (const authorization of accountTransferAuthorizations) {
    if (authorization.relationships['to-account'].data.id === toAccountId) {
      return true
    }
  }
  return false
}

export async function instantSettlement(
  receivingAccountId: string,
  amount: number
) {
  const primetrustClient = await getClient()
  const account = await primetrustClient.GetAccount(receivingAccountId)
  const settlementAccount = await primetrustClient.GetAccount(
    primetrustSettlementAccountId
  )

  const isSettlementToAccountTransferAuthorized = await isTransferAuthorized(
    settlementAccount.data.attributes.number,
    account.data.id
  )

  if (!isSettlementToAccountTransferAuthorized) {
    const accountTransferAuthorizationRequest = {
      'to-account-id': account.data.id,
      'from-account-name': settlementAccount.data.attributes.name,
      'from-account-number': settlementAccount.data.attributes.number
    }

    const createAuthorization =
      await primetrustClient.CreateAccountTransferAuthorization(
        accountTransferAuthorizationRequest
      )
    console.log(createAuthorization)
  }

  const accountCashTransferRequest = {
    amount: amount.toString(),
    'currency-type': 'USD',
    'from-account-id': primetrustSettlementAccountId,
    'to-account-id': account.data.id,
    reference: `${account.data.attributes.number}`
  }

  const accountCashTransfer = await primetrustClient.CreateAccountCashTransfer(
    accountCashTransferRequest
  )

  const accountCashTransferId = accountCashTransfer.data.id

  // approve account cash transfer: SANDBOX
  if (isDev) {
    const approveCashTransfer =
      await primetrustClient.SandboxApproveCashTransfer({
        accountCashTransferId
      })

    console.log('approveCashTransfer', approveCashTransfer)
  }
}

export async function transferFeesToFeesAccount(
  customerAccountId: string,
  fees: Fees
) {
  const primetrustClient = await getClient()
  const customerAccount = await primetrustClient.GetAccount(customerAccountId)
  const feesAccount = await primetrustClient.GetAccount(primetrustFeesAccountId)

  const isCustomerToFeesAccountTransferAuthorized = await isTransferAuthorized(
    customerAccount.data.attributes.number,
    feesAccount.data.id
  )

  if (!isCustomerToFeesAccountTransferAuthorized) {
    const accountTransferAuthorizationRequest = {
      'to-account-id': feesAccount.data.id,
      'from-account-name': customerAccount.data.attributes.name,
      'from-account-number': customerAccount.data.attributes.number
    }

    const createAuthorization =
      await primetrustClient.CreateAccountTransferAuthorization(
        accountTransferAuthorizationRequest
      )
  }

  const totalFeesToCharge = getTotalFeesRoundedUSD(fees)

  const accountCashTransferRequest = {
    amount: totalFeesToCharge.toString(),
    'currency-type': 'USD',
    'from-account-id': customerAccount.data.id,
    'to-account-id': feesAccount.data.id,
    reference: ``
  }

  const accountCashTransfer = await primetrustClient.CreateAccountCashTransfer(
    accountCashTransferRequest
  )

  const accountCashTransferId = accountCashTransfer.data.id

  // approve account cash transfer: SANDBOX
  if (isDev) {
    const approveCashTransfer =
      await primetrustClient.SandboxApproveCashTransfer({
        accountCashTransferId
      })

    console.log('approveCashTransfer', approveCashTransfer)
  }
}

/**
 * Represents a pending kyc account data.
 * Useful for mocks or as a dummy wait
 * response
 */
export const pendingKYCAccountData = {
  account: {
    status: AccountStatus.Pending
  },
  contacts: {
    kycRequiredActions: [],
    identityConfirmed: false,
    identityDocumentsVerified: false,
    amlCleared: false
  },
  checks: []
}

/**
 * Has account been opened? If so, we're open
 * for business and can start transacting
 *
 * @param data KYCAccountData
 * @returns boolean whether account is open for business
 */
export function isAccountOpen(data: KYCAccountData) {
  const { account } = data
  return account.status === 'opened'
}

/**
 * Has account been closed? Note this is not
 * a terminal state. Manual review can allow us
 * to reopen the account.
 *
 * @param data KYCAccountData
 * @returns boolean whether account is closed
 */
export function isAccountClosed(data: KYCAccountData) {
  const { account } = data
  return account.status === 'closed'
}

/**
 * Has account gone through KYC verification?
 * Separate from account opening; checks whether
 * we've passed all kyc checks required to open
 * account
 *
 * @param data KYCAccountData
 * @returns true if kyc data indicates account has passed kyc checks
 */
export function isKycVerified(data: KYCAccountData) {
  const { contacts } = data

  if (Object.keys(contacts.kycRequiredActions).length > 0) {
    return false
  }

  return (
    contacts.identityConfirmed &&
    contacts.identityDocumentsVerified &&
    contacts.amlCleared
  )
}

/**
 * Gets the required kyc checks as a list of
 * human-readable strings
 *
 * @param data KYCAccountData
 * @returns list of human-readable required instructions
 */
export function getKycRequiredActions(data: KYCAccountData): string[] {
  const { contacts } = data
  return Object.values(
    contacts.kycRequiredActions.map((action) => action.description)
  )
}

/**
 * Returns any pending checks. Usually you don't have
 * to use this, but helpful for debugging and internal
 * operations
 *
 * @param data KYCAccountData
 * @returns list of pending checks
 */
export function getPendingChecks(data: KYCAccountData) {
  const { checks } = data
  return checks.filter(
    (check) =>
      check.attributes.status === 'pending' ||
      check.attributes.status === 'denied'
  )
}
