import { ApiPromise } from '@polkadot/api'
import { WsProvider } from '@polkadot/rpc-provider'
import { options } from '@acala-network/api'
import { wssNodeEndpoint } from './config'

declare global {
  let polkadotApi: ApiPromise | undefined
}

let polkadotApi: ApiPromise

export async function getPolkadotApi() {
  if (!polkadotApi) {
    const provider = new WsProvider(wssNodeEndpoint)
    polkadotApi = new ApiPromise(options({ provider }))
    await polkadotApi.isReady
  }
  return polkadotApi
}

export async function getSystemParameters(polkadotApi?: ApiPromise) {
  if (!polkadotApi) {
    polkadotApi = await getPolkadotApi()
  }
  const params = await polkadotApi.rpc.system.properties()
  let decimals: number[]
  if (!params.tokenDecimals.isNone) {
    decimals = params.tokenDecimals.value.toHuman() as number[]
  } else {
    throw new Error('decimals in system parameters is not set')
  }

  let symbols: string[]
  if (!params.tokenSymbol.isNone) {
    symbols = params.tokenSymbol.value.toHuman() as string[]
  } else {
    throw new Error('tokenSymbol in system parameters is not set')
  }
  const symbolsDecimals: Record<string, number> = symbols.reduce(
    (acc: any, symbol: any, index: number) => ({
      ...acc,
      [symbol]: +decimals[index]
    }),
    {}
  )
  return {
    decimals,
    symbols,
    symbolsDecimals
  }
}

export const getPolkadotSymbolsDecimals = async (
  polkadotApi?: ApiPromise
): Promise<Record<string, number>> => {
  const polkadotSystemParams = await getSystemParameters(polkadotApi)
  return polkadotSystemParams.symbolsDecimals
}
