import React from 'react'

export const PanelContainer: React.FC<{ cardBgColor? }> = ({ children }) => {
  return (
    <main className='relative sm:mt-4'>
      <div className='max-w-7xl mx-auto'>
        <div className='max-w-xl mx-auto rounded-2xl bg-white'>
          <div className='py-4 sm:py-6 px-2 sm:px-4 rounded-xl'>
            <div className='border-gray-200'>{children}</div>
          </div>
        </div>
      </div>
    </main>
  )
}
