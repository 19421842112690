import { GeckoV3SimplePrice } from 'lib/types'
import useSWR from 'swr'

const geckoIds = ['tether', 'acala-dollar', 'polkadot', 'acala'].join(',')

function mapTokenNames(data: GeckoV3SimplePrice) {
  return {
    dot: data.polkadot,
    aUSD: data['acala-dollar'],
    aca: data.acala,
    tether: data.tether
  }
}

export const fetcher = async () => {
  const res = await fetch(
    `https://api.coingecko.com/api/v3/simple/price?ids=${geckoIds}&vs_currencies=usd`
  )
  const payload: GeckoV3SimplePrice = (await res.json()) as GeckoV3SimplePrice
  return mapTokenNames(payload)
}

export function usePriceFeed() {
  const { data, error } = useSWR(
    `https://api.coingecko.com/api/v3/simple`,
    fetcher,
    {
      refreshInterval: 3000,
      revalidateIfStale: true
    }
  )

  return {
    data,
    isLoading: !error && !data,
    error: error
  }
}
