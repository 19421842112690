import { Customer } from '@prisma/client'

import { LoginSession } from 'lib/auth'
import {
  AchAccount,
  ApiSuccess,
  Card,
  CreateAccountData,
  CreateDocumentData,
  KYCAccountData,
  OrderEventFulfilledBuy,
  SuccessResponse,
  FundsTransferMethod,
  CreateKYCDocumentCheckResponse,
  Fees,
  PaymentMethod,
  TokenCurrency,
  OrderDetailsResponse
} from 'lib/types'

import {
  ICreateCreditCardResourceResponse,
  ICreateContributionResponse,
  IKYCCreateDocumentCheckResponse
} from 'lib/partner/primetrust/interfaces'

import { Response as VerificationCodeResponse } from 'pages/api/cards/verificationCode'
import { pendingKYCAccountData } from 'lib/utils/primetrust'
import { NewlyCreatedAccount } from 'lib/utils/createPolkadotAccount'
import { Response as FundsTransferResponse } from 'pages/api/fundsTransfers'

class ApiClientError extends Error {
  isApiClientError = true
  response: Response

  constructor(response: Response) {
    super('ApiClientError')
    this.response = response
  }
}

export function isApiClientError(payload: any): payload is ApiClientError {
  return payload?.isApiClientError === true
}

export async function handleErrors(response: Response) {
  if (!response.ok) {
    throw new ApiClientError(response)
  }
  return response
}

/**
 * Abstracts a generic GET method
 * @param endpoint everything after the /api/ prefix
 * @param params object representing the query params
 * @returns Response object from native `fetch` lib
 */
const get = (
  endpoint: string,
  params: Record<string, string> | undefined
): Promise<Response> => {
  if (endpoint.startsWith('/')) {
    endpoint = endpoint.slice(1)
  }

  // TODO urlencode params
  return fetch(`/api/${endpoint}?${new URLSearchParams(params)}`).then(
    handleErrors
  )
}

/**
 * Abstracts a generic POST endpoint. Supports
 * JSON-encoded body only
 *
 * @param endpoint everything after the /api/ prefix
 * @param data JSON object representing the body of the request
 * @returns Response object from native `fetch` lib
 */
const post = (
  endpoint: string,
  data: Record<string, unknown> | undefined
): Promise<Response> => {
  if (endpoint.startsWith('/')) {
    endpoint = endpoint.slice(1)
  }

  return fetch(`/api/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(handleErrors)
}

/**
 * Posts non-json objects. Use this for mixed
 * content types like mixing form data and multi-part
 *
 * @param endpoint everything after the /api/ prefix
 * @param data a FormData object representing the body of the request
 * @returns Response object from native `fetch` lib
 */
const postFormData = (endpoint: string, body: FormData): Promise<Response> => {
  if (endpoint.startsWith('/')) {
    endpoint = endpoint.slice(1)
  }

  return fetch(`/api/${endpoint}`, {
    method: 'POST',
    body
  }).then(handleErrors)
}

const user = async (): Promise<{ user: LoginSession | null }> =>
  api
    .post('/user', {})
    .then((res) => res.json())
    .then((data) => {
      return { user: data?.user || null }
    })

const customer = async (): Promise<{ customer: Customer | null }> =>
  api
    .post('/customer', {})
    .then((res) => res.json())
    .then((data) => {
      return { customer: data?.customer || null }
    })

const getKYCAccount = async (accountId: string): Promise<KYCAccountData> =>
  api
    .get(`/kyc/account/${accountId}`, {})
    .then((res) => res.json())
    .then((data) => {
      return data as KYCAccountData
    })
    .catch((err) => {
      // it's possible for a kyc-progress account
      // to not exist yet, especially if we've just
      // created it via the api. to account for the lag,
      // if we get a 404, treat the account as pending
      if (isApiClientError(err)) {
        if (err.response.status === 404) {
          return pendingKYCAccountData
        }
      }
      throw err
    })

const createKYCAccount = async (
  data: Record<string, unknown>
): Promise<CreateAccountData> =>
  api
    .post(`/kyc/account`, data)
    .then((res) => res.json())
    .then((data) => {
      return data as CreateAccountData
    })

const approveKYCAccount = async (accountId: string): Promise<SuccessResponse> =>
  api
    .post(`/kyc/account/${accountId}/approve`, {})
    .then((res) => res.json())
    .then((data) => {
      return data as SuccessResponse
    })

const uploadKYCDocument = async (
  data: FormData
): Promise<CreateKYCDocumentCheckResponse> =>
  api
    .postFormData('/kyc/document', data)
    .then((res) => res.json())
    .then((data) => {
      return data as CreateKYCDocumentCheckResponse
    })

const createNonCustodial = async (): Promise<NewlyCreatedAccount> =>
  api
    .post('/wallet', {})
    .then((res) => res.json())
    .then((data) => {
      return data as NewlyCreatedAccount
    })

const getFundsTransfers = async (): Promise<FundsTransferResponse> =>
  api
    .get('/fundsTransfers', {})
    .then((res) => res.json())
    .then((data) => {
      return data as FundsTransferResponse
    })

const createCardsCreditCardResource =
  async (): Promise<ICreateCreditCardResourceResponse> =>
    api
      .post('/cards/creditCardResource', {})
      .then((res) => res.json())
      .then((data) => {
        return data as ICreateCreditCardResourceResponse
      })

const getCardVerification = async (
  resourceId: string
): Promise<VerificationCodeResponse> =>
  api
    .get('/cards/verificationCode', { resourceId })
    .then((res) => res.json())
    .then((data) => {
      return data as VerificationCodeResponse
    })

const getCardsFundsTransferMethods = async (): Promise<Card[]> =>
  api
    .get('/cards/fundsTransferMethods', {})
    .then((res) => res.json())
    .then((data) => {
      return data as Card[]
    })

const getAchFundsTransferMethods = async (): Promise<AchAccount[]> =>
  api
    .get('/ach/fundsTransferMethods', {})
    .then((res) => res.json())
    .then((data) => {
      return data as AchAccount[]
    })

const createCardsPendingContribution = async (
  fundsTransferMethodId: string,
  amount: number
): Promise<ICreateContributionResponse> =>
  api
    .post('/cards/pendingContribution', {
      fundsTransferMethodId,
      amount
    })
    .then((res) => res.json())
    .then((data) => {
      return data as ICreateContributionResponse
    })

const createCardsOrder = async (
  contributionId: string,
  walletAddress: string,
  tokenCurrency: TokenCurrency,
  fees: Fees | undefined,
  exchangeRate: number
): Promise<ApiSuccess<OrderEventFulfilledBuy>> =>
  api
    .post('/cards/order', {
      contributionId,
      walletAddress,
      tokenCurrency,
      fees,
      exchangeRate
    })
    .then((res) => res.json())
    .then((data) => {
      return data as ApiSuccess<OrderEventFulfilledBuy>
    })

const createAchPlaidProcessorToken = async (
  plaidAccountId: string
): Promise<ApiSuccess<FundsTransferMethod>> =>
  api
    .post('/ach/plaid/processorToken', {
      plaidAccountId
    })
    .then((res) => res.json())
    .then((data) => {
      return data as ApiSuccess<FundsTransferMethod>
    })

const createAchOrder = async (
  amount: number,
  walletAddress: string,
  fundsTransferMethodId: string,
  tokenCurrency: TokenCurrency,
  fees: Fees | undefined,
  exchangeRate: number
): Promise<ApiSuccess<OrderEventFulfilledBuy>> =>
  api
    .post('/ach/order', {
      amount: amount,
      walletAddress: walletAddress,
      fundsTransferMethodId: fundsTransferMethodId,
      tokenCurrency: tokenCurrency,
      fees: fees,
      exchangeRate: exchangeRate
    })
    .then((res) => res.json())
    .then((data) => {
      return data as ApiSuccess<OrderEventFulfilledBuy>
    })

const getFees = async (
  amount: number,
  paymentMethod: PaymentMethod,
  tokenCurrency: TokenCurrency,
  useDefaultNetworkFees = false
): Promise<ApiSuccess<Fees>> =>
  api
    .post('/fees', {
      amount: amount,
      paymentMethod: paymentMethod,
      tokenCurrency: tokenCurrency,
      useDefaultNetworkFees: useDefaultNetworkFees
    })
    .then((res) => res.json())
    .then((data) => {
      return data as ApiSuccess<Fees>
    })

const getOrderDetails = async (
  orderId: string
): Promise<ApiSuccess<OrderDetailsResponse>> =>
  api
    .post('/orderDetails', {
      orderId: orderId
    })
    .then((res) => res.json())
    .then((data) => {
      return data as ApiSuccess<OrderDetailsResponse>
    })
// Fetch-based API calls.
// These can all be used as `fetcher` functions
// to `useSWR`

const api = {
  get,
  post,
  postFormData,
  user,
  customer,
  getKYCAccount,
  createKYCAccount,
  approveKYCAccount,
  uploadKYCDocument,
  createNonCustodial,
  getFundsTransfers,
  createAchPlaidProcessorToken,
  createAchOrder,
  getAchFundsTransferMethods,
  createCardsCreditCardResource,
  createCardsPendingContribution,
  createCardsOrder,
  getCardVerification,
  getCardsFundsTransferMethods,
  getFees,
  getOrderDetails
}

export default api
